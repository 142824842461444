@CHARSET "UTF-8";

.menuDivId
{
	
	background-color : #FF4500 ;
	
	width : 100% ;
	float : left ;
	
	
	
	
	text-align : left ;
	
	float : left ;
	
}

/*
.menuItemDiv a 
{
	text-decoration : none ;
	color : white ;
}
*/


.menuItemDiv
{
	color : black ;
	float : left ;
	height : 35px ;
	
	
	text-align : left ;
	font-size : 1.2em;
	
	margin-top :9px ;
	cursor: pointer;
	
}



.divMenuIconBlack
{
	width : 100% ;
	float : left ;
	height:30px ;
	background-color : #FF4500 ;
}

.divMenuIconGrey
{
	width : 100% ;
	float : left ;
	height:30px ;
	background-color : #202020;
}

span#hiddingMenuDivForMenu
{
	
	float : right ;
	font-size : 1.5em;
	color : white ;
}

@media screen and (min-width : 600px) {
	.menuItemDivResp{
		width : 25% ;
	}
	.divMenuIconResp{
		display: none;
	}
}

@media screen and (max-width : 600px) {
	.menuItemDivResp{
		width : 100% ;
	}
	.divMenuIconResp{
		display: block;
	}
}





